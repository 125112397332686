<template>
  <div>index</div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>
